<template>
    <div class="customer-contacts row mt-5">
        <div class="col-md-4 input-item">
            <Input :label="$t('input-name')"
                   :inputtype="'text'"
                   :error="formErrors.includes('name')"
                   :content="form.name"
                   v-model="form.name"
                   @input="handleInput"
            />
        </div>
        <div class="col-md-4 input-item">
            <Input
                :label="$t('input-phone')"
                :inputtype="'text'"
                :error="formErrors.includes('phone')"
                :content="form.phone"
                v-model="form.phone"
                @input="handleInput"
            />
        </div>
        <div class="col-md-4 input-item">
            <Input
                :label="'Email'"
                :inputtype="'email'"
                :error="formErrors.includes('email')"
                :content="form.email"
                v-model="form.email"
                @input="handleInput"
            />
        </div>
        <div v-bind:class="{'row input-item text-area w-100': true,}">
            <TextArea :label="$t('additional-information')"
                  :content="form.message"
                  v-model="form.message"
            />
        </div>
    </div>
</template>

<script>
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import Input from "@/components/Common/Inputs/Input/Input";

export default {
    name: "CustomerInfoForm",
    components: {
        Input,
        TextArea
    },
    props: {
        selectedServiceType: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            form: {
                name: null,
                phone: null,
                email: null,
                message: null
            },
            formErrors: []
        }
    },
    methods: {
        handleInput() {
            this.validateForm();
            this.$emit('update:modelValue', {
                form: this.form,
                isValid: this.formErrors.length === 0,
            });
        },
        validateForm() {
            this.formErrors = [];

            if (this.form.name === null || this.form.name === '' || this.form.name.length < 2) {
                this.formErrors.push('name');
            }

            if (this.form.phone === null || this.form.phone === '' || this.form.phone.length < 5) {
                this.formErrors.push('phone');
            }

            const mailRegex = /\S+@\S+\.\S+/;
            if (this.form.email === null || this.form.email === '' || !mailRegex.test(this.form.email)) {
                this.formErrors.push('email');
            }

            return this.formErrors.length === 0;
        }
    },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.input-info {
    font-size: 11px;
    width: 10px;
    padding-left: 5px;
}

.input-container {
    text-align: center;
}

.input-item {
    margin: $spacing-1 0;
}

.text-area, .file-input {
    height: 15rem;
    padding: 5px;
}


</style>
