import Button from "@/components/Common/Buttons/Button/Button";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import StepProgressBar from "@/components/Components/StepProgressBar/StepProgressBar";
import PremiumAdSelection from "@/components/Components/PremiumAdSelection/PremiumAdSelection";
import CreditWallet from "@/components/Components/CreditWallet/CreditWallet";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import xIcon from "@/components/Common/Icons/xIcon/";
import SelectCleaningStage1 from "@/views/SelectCleaningServicePage/SelectCleaningStage1";
import SelectMovingStage1 from "@/views/SelectCleaningServicePage/SelectMovingStage1";
import SelectCleaningStage2 from "@/views/SelectCleaningServicePage/SelectCleaningStage2";
import SelectCleaningStage3 from "@/views/SelectCleaningServicePage/SelectCleaningStage3";
import Footer from "@/components/Footer/Footer";
import CleaningLocationHoursInfo from "@/components/Components/CleaningLocationHoursInfo/CleaningLocationHoursInfo";

export default {
    name: 'SelectCleaningServicePage',
    components: {
        Button,
        Dropdown,
        InputField,
        StepProgressBar,
        TextArea,
        FileInput,
        CreditWallet,
        PremiumAdSelection,
        SelectCleaningStage1,
        SelectMovingStage1,
        SelectCleaningStage2,
        SelectCleaningStage3,
        CleaningLocationHoursInfo,
        xIcon,
        Footer
    },
    data() {
        return {
            service: null, //cleaning or moving
            selectedServiceType: null,
            selectedServices: {},
            stage: 0,
            selectedPlan: 'regular',
            stage2OneTimeData: {},
            stage2SubscriptionData: {},
            stage2DeepData: {},
            stage2MovingData: {},
            clientInfo: {},
            subscriptionServices: [
                {
                    cleaningTitle: 'once-a-week',
                    cleaningPrice: 6.30,
                    buttonText: 'choose-service',

                },
                {
                    cleaningTitle: 'twice-a-week',
                    cleaningPrice: 9.90,
                    buttonText: 'choose-service',
                },
                {
                    cleaningTitle: 'three-times-a-week',
                    cleaningPrice: 15.40,
                    buttonText: 'choose-service',
                },
                {
                    cleaningTitle: 'monday-to-friday',
                    cleaningPrice: 21.00,
                    buttonText: 'choose-service',
                },
            ],
        };
    },
    async mounted() {
        this.service = this.$route.name === 'SelectMovingServicePage' ? 'moving' : 'cleaning';
        const serviceType = this.$route.query.type ?? 'one-time';

        this.selectedServices = this.$store.state.cleaning.selectedServices;
        if (serviceType) {
            this.selectedServiceType = serviceType;
        }

        await this.$store.dispatch('cleaning/deleteAllSelectedService');
    },
    methods: {
        handleServiceSelected(serviceType) {
            this.selectedServiceType = serviceType;
        },
        async goToStage(goToStage) {
            if (goToStage === 1) {
                this.stage2OneTimeData = {}
                this.stage2DeepData = {}
                this.stage2SubscriptionData = {}
            }

            if (goToStage === 2) {
                if (this.selectedServiceType === 'deep' && !this.stage2DeepData.isValid) {
                    await this.$store.dispatch('utils/openNotificationModal', {
                        type: 'error',
                        message: this.$t('please-fill-all-inputs'),
                    });
                    return;
                }

                if (this.selectedServiceType === 'subscription') {
                    if (this.isNumericValueNotValid(this.stage2SubscriptionData.sqm)) {
                        await this.$store.dispatch('utils/openNotificationModal', {
                            type: 'error',
                            message: this.$t('please-fill-all-inputs'),
                        });
                        return;
                    } else {
                        let vm = this;
                        const service = this.subscriptionServices.findLast((item) => {
                            return item.cleaningTitle  === vm.$route.query.subsType;
                        });
                        await this.$store.dispatch(
                            'cleaning/addSubscriptionServiceToCard',
                            {
                                serviceKey: 'subscription-cleaning-service',
                                inputType: 'plus-minus',
                                price: service.cleaningPrice,
                                sqm: this.stage2SubscriptionData.sqm,
                                months: this.stage2SubscriptionData.months,
                                subsType: this.$route.query.subsType,
                            }
                        );
                    }
                }

                if (this.selectedServiceType === 'moving') {
                    if (!this.stage2MovingData.isValid) {
                        await this.$store.dispatch('utils/openNotificationModal', {
                            type: 'error',
                            message: this.$t('please-fill-all-inputs'),
                        });
                        return;
                    } else {
                        Object.keys(this.stage2MovingData.form).forEach(key => {
                            const count = this.stage2MovingData.form[key];
                            let price = 0;
                            if (key === 'one') price += 50 * 1.2;
                            if (key === 'two') price += 80 * 1.2;
                            if (key === 'three') price += 100 * 1.2;
                            if (key === 'four') price += 120 * 1.2;
                            this.$store.dispatch(
                                'cleaning/addMovingServiceToCard',
                                {
                                    serviceKey: 'moving-service-' + key + "-workers",
                                    inputType: 'plus-minus',
                                    price: price,
                                    quantity: count
                                }
                            );
                        });
                    }
                }

                if (this.selectedServiceType === 'transport') {
                    if (this.isNumericValueNotValid(this.stage2MovingData)) {
                        await this.$store.dispatch('utils/openNotificationModal', {
                            type: 'error',
                            message: this.$t('please-fill-all-inputs'),
                        });
                        return;
                    } else {
                        const count = parseInt(this.stage2MovingData);
                        let price = 50
                        this.$store.dispatch(
                            'cleaning/addMovingServiceToCard',
                            {
                                serviceKey: 'moving-transport-service',
                                inputType: 'plus-minus',
                                price: price,
                                hours: count
                            }
                        );
                    }
                }

                if (this.selectedServiceType === 'helper') {
                    if (this.isNumericValueNotValid(this.stage2MovingData)) {
                        await this.$store.dispatch('utils/openNotificationModal', {
                            type: 'error',
                            message: this.$t('please-fill-all-inputs'),
                        });
                        return;
                    } else {
                        const count = parseInt(this.stage2MovingData);
                        let price = 30
                        this.$store.dispatch(
                            'cleaning/addMovingServiceToCard',
                            {
                                serviceKey: 'moving-helper-service',
                                inputType: 'plus-minus',
                                price: price,
                                hours: count
                            }
                        );
                    }
                }
            }

            this.stage = goToStage;

            await this.$store.dispatch("cleaning/calculateTotalPrice");
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        },
        isNumericValueNotValid(value) {
            return typeof parseInt(value) !== 'number'
                || parseInt(value) <= 0
                || value === ''
                || isNaN(value)
        },
        async makeOrder() {
            this.selectedServices = this.$store.state.cleaning.selectedServices;

            //Deep clieanign
            let clientInfo = this.clientInfo;
            if (this.selectedServiceType === 'deep') {
                clientInfo = this.stage2DeepData;
            }

            if (!await this.validateUserInfoForm(clientInfo)) {
                return;
            }

            if (this.selectedServiceType === 'subscription') {
                this.selectedServices = {
                    'subscription-cleaning-service': {
                        'subscriptionPlan': this.$route.query.subsType,
                        'quantity': this.stage2SubscriptionData.sqm,
                        'months': this.stage2SubscriptionData.months
                    }
                };
            }

            if (this.selectedServiceType === 'deep') {
                await this.$store.dispatch('cleaning/makeDeepCleaningOrder', {
                    email: clientInfo.form.email,
                    message: clientInfo.form.message,
                    name: clientInfo.form.name,
                    phone: clientInfo.form.phone,
                });
                this.$router.push({name: 'CleaningPage'})
                await this.$store.dispatch('utils/openSuccessModal', this.$t('order-success-message'));
                return
            }

            if (this.service === 'cleaning' && this.$store.state.cleaning.totalPrice < 130) {
                await this.$store.dispatch('utils/openErrorModal', this.$t('cleaning-minimum-order-amount-error-message'));
                return;
            }

            try {
                const response  = await this.$store.dispatch('cleaning/makeOrder', {
                    serviceType: this.selectedServiceType,
                    services: this.selectedServices,
                    clientInfo: clientInfo.form
                });

                if (!response.hasOwnProperty('paymentUrl')) {
                    throw new Error('err');
                }

                await this.$store.dispatch('utils/openNotificationModal', {type: 'loading'});
                await this.$store.dispatch('cleaning/deleteAllSelectedService');

                const paymentUrl = response.paymentUrl;
                window.location.replace(paymentUrl);

                this.$router.push({name: 'CleaningPage'})
            } catch (err) {
                await this.$store.dispatch('utils/openErrorModal', this.$t('payment-failed-message'));
            }
        },
        async validateUserInfoForm(clientInfo) {
            if (!clientInfo.hasOwnProperty('isValid') || !clientInfo.isValid) {
                console.log('validation failed');
                console.log(this.clientInfo);
                await this.$store.dispatch('utils/openNotificationModal', {
                    type: 'error',
                    message: this.$t('please-fill-all-inputs'),
                });
                return false;
            }

            return true;
        }
    },
};
