<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="service-container text-center">
    <div class="service-title"> {{ $t(serviceTitle) }}</div>
    <div> {{ $t('prices-from') }}:</div>
    <div class="price-amount"> {{ servicePrice.toFixed(2) }} {{ $t('price-per-square-meter') }}&sup2; {{ $t('monthly') }}</div>
    <div class="service-button" @click="goToNextStage">
      <Button :theme="'solid-orange'" :size="'medium'" :text="$t(buttonText)"/>
    </div>
  </div>
</template>
