import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import PlusMinusInput from "@/components/Common/Inputs/PlusMinusInput/PlusMinusInput";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import xIcon from "@/components/Common/Icons/xIcon/";

export default {
    name: 'CleaningCartItem',
    props: {
        title: {
            type: String,
            default: ''
        },
        quantity: {
            type: Number,
            default: null
        },
        price: {
            type: String,
            default: ''
        },
        serviceKey: {
            type: String,
            default: null
        },
        sqm: {
            type: Number,
            default: null
        },
        months: {
            type: Number,
            default: null
        },
        hours: {
            type: Number,
            default: null
        },
        severity: {
            type: Number,
            default: null
        },
        subsType: {
            type: Number,
            default: null
        }
    },
    components: {
        Dropdown,
        InputField,
        Button,
        PlusMinusInput,
        HeartIcon,
        xIcon
    },
    data() {
        return {
            severityOptions: {},
            calculatedPrice: 0,
        }
    },
    mounted() {
        this.severityOptions[1] = this.$t('normal');
        this.severityOptions[2] = this.$t('medium');
        this.severityOptions[3] = this.$t('high');

        let quantity = 0;
        if (this.$props.quantity) {
            quantity = this.$props.quantity;
        } else if (this.$props.sqm) {
            quantity = this.$props.sqm;
        } else if (this.$props.hours) {
            quantity = this.$props.hours;
        }

        if (this.$props.months) {
            quantity = quantity * this.$props.months;
        }

        this.calculatedPrice = parseFloat(this.$props.price) * parseInt(quantity);
    },
    methods: {
        async removeItemFromCart(id) {
            this.$emit('onItemRemoved', id);
            await this.$store.dispatch('cleaning/removeServiceFromCart', id);
        },
        getSeverityMultiplicator(severity) {
            let severitymultiplicator = 1
            switch (severity) {
                case "2":
                    severitymultiplicator = 1.3
                    break;
                case "3":
                    severitymultiplicator = 1.5
                    break;
            }

            return severitymultiplicator;
        },
        multiplyPriceBySeverity(price, severity) {
            let severitymultiplicator = this.getSeverityMultiplicator(severity);
            return price * severitymultiplicator;
        }
    }
}