<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="cart-item-container row">

      <div class="">
        <div class="d-flex align-items-start">
          <div class="cart-item-title">
            {{ $t(title) }}
          </div>
          <div class="icon-container ms-auto cursor-pointer" v-if="serviceKey">
            <xIcon @click="removeItemFromCart(serviceKey)"/>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="plus-minus-input me-auto">
            <div class="ps-2" v-if="sqm">{{ sqm }} {{ $t('sqm') }}</div>
            <div v-else-if="quantity">
              <span class="ps-2">{{ $t('quantity') }}: </span>
              <span>&nbsp; {{ quantity }}</span>
              <span>&nbsp; {{ $t('count') }}</span>
            </div>
            <div v-else-if="hours">
              <span class="ps-2">{{ $t('quantity') }}: </span>
              <span>&nbsp; {{ hours }}</span>
              <span>&nbsp; {{ $t('hours') }}</span>
            </div>
            <div v-if="!subsType && severity">
              <span class="ps-2">{{ $t('dirtiness') }}: </span>
              <span>&nbsp;{{ severityOptions[severity] }}</span>
              <span v-if="severity > 1">&nbsp; + {{ parseInt((getSeverityMultiplicator(severity)-1) * 100) }}%</span>
            </div>
            <div v-if="subsType">
              <span class="ps-2">{{ $t('plan') }}: </span>
              <span>&nbsp;{{ $t(subsType) }}</span>
            </div>
            <div v-if="months">
              <span class="ps-2">{{ $t('subscription-for') }}: </span>
              <span>&nbsp;{{ months }}</span>
              <span class="ps-1">{{ $t('months') }}</span>
            </div>
          </div>
          <div class="price-container">
            <div class="discounted-price">
              <div class="price-amount">
                <div v-if="severity">
                  {{ parseFloat(multiplyPriceBySeverity(calculatedPrice, severity)).toFixed(2) }} {{ $t('currency') }}
                </div>
                <div v-else>
                  <div>{{ parseFloat(calculatedPrice).toFixed(2) }} {{ $t('currency') }}</div>
                </div>
                <div class="single-price">{{ $t('single-item-price')}}: {{ parseFloat(price).toFixed(2) }} {{ $t('currency') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
