<template>
    <div class="d-flex justify-content-center w-100">
        <div class="col-md-7 moving-navigation-icons">
            <div class="row">
                <div class="col-md-4 col-12"
                     @click="showService('moving')"
                     :class="{ 'service-button-active': selectedServiceType === 'moving' }">
                    <div class="service-wrapper text-center d-flex  align-items-center">
                        <div class="service-icon" style="margin: 14px 0;">
                            <MovingIcon/>
                        </div>
                        <div class="service-title"> {{ $t('moving-moving-title') }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-12"
                     @click="showService('transport')"
                     :class="{ 'service-button-active': selectedServiceType === 'transport' }"
                >
                    <div class="service-wrapper d-flex  align-items-center text-center">
                        <div class="service-icon">
                            <MovingTransportationIcon/>
                        </div>
                        <div class="service-title "> {{ $t('moving-transport-title') }}</div>
                    </div>
                </div>
                <div class="col-md-4 col-12 "
                     @click="showService('helper')"
                     :class="{ 'service-button-active': selectedServiceType === 'helper' }"
                >
                    <div class="service-wrapper text-center d-flex  align-items-center">
                        <div class="service-icon">
                            <MovingHelperIcon/>
                        </div>
                        <div class="service-title"> {{ $t('moving-helper-title') }}</div>
                    </div>
                </div>
            </div>
            <div class="service-subtitle text-center"> {{ $t('moving-cleaning-subtitle-stage1') }}</div>
        </div>
    </div>

    <div class="service-deep-cleaning">
        <div class="row d-flex justify-content-center w-100">
            <div class="col-md-8">
                <div class="service-container text-center">
                    {{ $t('select-moving-content-stage1') }}
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import SingleCleaningIcon from "@/components/Common/Icons/SingleCleaningIcon"
import DeepCleaningIcon from "@/components/Common/Icons/DeepCleaningIcon"
import SubscriptionCleaningIcon from "@/components/Common/Icons/SubscriptionCleaningIcon"
import GalleryMain from "@/components/Components/GalleryMain";
import Button from "@/components/Common/Buttons/Button/Button";
import InputField from "@/components/Common/Inputs/Input/Input";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import ServiceItemDropdown from "@/views/SelectCleaningServicePage/ServiceItemDropdown";
import DeepCleaningServiceSelectBox
    from "@/components/Components/DeepCleaningServiceSelectBox/DeepCleaningServiceSelectBox";
import TickIcon from "@/components/Common/Icons/TickIcon/";
import SubscriptionCleaningServiceSelectBox
    from "@/components/Components/SubscriptionCleaningServiceSelectBox/SubscriptionCleaningServiceSelectBox";
import MovingIcon from "@/components/Common/Icons/MovingIcon.vue";
import MovingTransportationIcon from "@/components/Common/Icons/MovingTransportationIcon.vue";
import MovingHelperIcon from "@/components/Common/Icons/MovingHelperIcon.vue";


export default {
    name: "SelectMovingStage1",
    components: {
        MovingHelperIcon,
        MovingTransportationIcon,
        MovingIcon,
        GalleryMain,
        Button,
        InputField,
        TextArea,
        SingleCleaningIcon,
        SubscriptionCleaningIcon,
        DeepCleaningIcon,
        ServiceItemDropdown,
        DeepCleaningServiceSelectBox,
        SubscriptionCleaningServiceSelectBox,
        TickIcon
    },
    props: {
        goToStage: Function,
        initiallySelectedService: {
            type: String
        },
    },
    data() {
        return {
            selectedServiceType: 'one-time',
        }
    },
    mounted() {
      this.selectedServiceType = this.initiallySelectedService;
    },
    methods: {
        showService(serviceType) {
            this.selectedServiceType = serviceType;
            this.$emit('service-selected', serviceType);
        },
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.service-wrapper {
    margin-top: $spacing-5;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 59px 0px #00000021;
    border-radius: 20px;
    color: $color-orange-main;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    padding: $spacing-1 $spacing-3;

    &:hover {
        background: $color-orange-main;
        color: $color-text-primary-dark;
        transition: 0.3s ease-in-out;
    }

    .service-title {
        @include subtitle-2;
        margin-left: $spacing-1;
    }
}

.service-subtitle {
    @include subtitle-3;
    margin: $spacing-5;
}

.service-button-active {
    .service-wrapper {
        background: $color-orange-main;
        color: $color-text-primary-dark;
        transition: 0.3s ease-in-out;
    }
}

.service-container {
    padding: $spacing-5 $spacing-2;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 59px 0px #00000021;
    border-radius: 20px;
    transition: 0.5s;
    margin: $spacing-2 0;
}

@media (min-width: 768px) {
  .moving-navigation-icons {
    min-width: 790px !important;
  }
}
</style>
