<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{'create-roommate-offer': true}" class="container" v-if="selectedServiceType">
    <div class="add-roommate-title text-center mb-5" >
      {{ $t('select-cleaning-service-title') }}
    </div>
    <div class="row">
      <StepProgressBar :stage="stage"
                       :step1="$t('select-service')"
                       :step2="$t('data-entry')"
                       :step3="$t('send-request')"/>
    </div>

    <div v-if="stage === 0" class="input-container row justify-content-center stage-1">
      <div class="">
          <SelectCleaningStage1
              v-if="service === 'cleaning'"
              :initially-selected-service="selectedServiceType"
              @service-selected="handleServiceSelected"
              :subscription-services-select="subscriptionServices"
              :go-to-stage="goToStage"
          />
          <SelectMovingStage1
              v-if="service === 'moving'"
              :initially-selected-service="selectedServiceType"
              @service-selected="handleServiceSelected"
              :go-to-stage="goToStage"
          />
        <div class="button d-flex justify-content-center" v-if="service === 'moving' || selectedServiceType === 'one-time'">
          <div class="col-md-9">
            <div class="d-flex " v-bind:class="{'row mt-4': true}">
              <div class="col-md-4 add-roommate-buttons ms-auto" @click="goToStage(1)">
                <Button :text="$t('continue')" :size="'medium'" :theme="'solid-orange'"/>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5 ">
          <div class="col-md-7 text-left">
            <CleaningLocationHoursInfo :service="service"/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="stage === 1" class="input-container row justify-content-center stage-2">
      <div style="width: 800px;">
          <SelectCleaningStage2 v-if="selectedServiceType === 'one-time'"
                                :selected-service-type="selectedServiceType"
                                v-model="stage2OneTimeData"/>
          <SelectCleaningStage2 v-if="selectedServiceType === 'deep'"
                                :selected-service-type="selectedServiceType"
                                v-model="stage2DeepData"/>
          <SelectCleaningStage2 v-if="selectedServiceType === 'subscription'"
                                :selected-service-type="selectedServiceType"
                                v-model="stage2SubscriptionData"/>
          <SelectCleaningStage2 v-if="selectedServiceType === 'moving'"
                                :selected-service-type="selectedServiceType"
                                v-model="stage2MovingData"/>
          <SelectCleaningStage2 v-if="selectedServiceType === 'transport'"
                                :selected-service-type="selectedServiceType"
                                v-model="stage2MovingData"/>
          <SelectCleaningStage2 v-if="selectedServiceType === 'helper'"
                                :selected-service-type="selectedServiceType"
                                v-model="stage2MovingData"/>
      </div>
      <div v-bind:class="{'row mt-4': true,  'col-md-7': true}">
        <div class="col-md-4 me-auto add-roommate-buttons" @click="goToStage(0)">
          <Button :text="$t('back')" :size="'medium'" :theme="'outline-grey'"/>
        </div>
        <div class="col-md-4 add-roommate-buttons" @click="goToStage(2)">
          <Button :text="$t('continue')" :size="'medium'" :theme="'solid-orange'"/>
        </div>
      </div>
    </div>
    <div v-if="stage === 2" class="row justify-content-center stage-3  ">
      <div class="col-md-7">
          <SelectCleaningStage3 v-if="selectedServiceType === 'deep'"
                                :selected-service-type="selectedServiceType"
                                :client-info="stage2DeepData"
                                v-model="clientInfo"/>
          <SelectCleaningStage3 v-if="selectedServiceType === 'one-time'"
                                :selected-service-type="selectedServiceType"
                                :client-info="stage2OneTimeData"
                                v-model="clientInfo"/>
          <SelectCleaningStage3 v-if="selectedServiceType === 'subscription'"
                                :selected-service-type="selectedServiceType"
                                :client-info="stage2SubscriptionData"
                                v-model="clientInfo"/>
          <SelectCleaningStage3 v-if="selectedServiceType === 'moving'"
                                :selected-service-type="selectedServiceType"
                                :client-info="stage2MovingData"
                                v-model="clientInfo"/>
          <SelectCleaningStage3 v-if="selectedServiceType === 'transport'"
                                :selected-service-type="selectedServiceType"
                                :client-info="stage2MovingData"
                                v-model="clientInfo"/>
          <SelectCleaningStage3 v-if="selectedServiceType === 'helper'"
                                :selected-service-type="selectedServiceType"
                                :client-info="stage2MovingData"
                                v-model="clientInfo"/>
      </div>
      <div v-bind:class="{'row col mt-4': true,  'col-md-7': true}">
        <div class="col-md-4 me-auto add-roommate-buttons" @click="goToStage(1)">
          <Button :text="$t('back')" :size="'medium'" :theme="'outline-grey'"/>
        </div>
        <div class="col-md-4 add-roommate-buttons">
          <Button v-if="['one-time', 'subscription', 'moving', 'transport', 'helper'].includes(selectedServiceType)" :text="$t('payment')"
                  :size="'medium'" :theme="'solid-orange'"
                  @click="makeOrder()"/>
          <Button v-if="selectedServiceType === 'deep'" :text="$t('request-service')" :size="'medium'"
                  :theme="'solid-orange'" @click="makeOrder()"/>
        </div>
      </div>
    </div>
  </div>
</template>
