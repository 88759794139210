<template>
  <div v-bind:class="{ 'open-terms-box': termsBoxActive }">
    <div class="terms-button-wrapper" >
      <div class="d-flex terms-button-mobile"  v-on:click="toggleTermsBox">
        <div class="logo-container me-3">
          <div v-if="icon === 'mattresses'">
            <MattressesCleanngIcon/>
          </div>
          <div v-if="icon === 'window'">
            <WindowIcon/>
          </div>
          <div v-if="icon === 'household-appliances'">
            <HomeAppliancesIcon/>
          </div>
          <div v-if="icon === 'others'">
            <OthersCleaningIcon/>
          </div>
          <div v-if="icon === 'hard-floor'">
            <HardfloorSurfaceIcon/>
          </div>
          <div v-if="icon === 'soft-floor'">
            <SoftFloorSurfaceIcon/>
          </div>
          <div v-if="icon === 'soft-furniture'">
            <SoftFurnitureIcon/>
          </div>
          <div v-if="icon === 'leather-furniture'">
            <LeatherFurnitureIcon/>
          </div>
          <div v-if="icon === 'auto-cleaning'">
            <AutocleaningIcon/>
          </div>
          <div v-if="icon === 'sanitary-room'">
            <SanitaryRoomIcon/>
          </div>
        </div>
        <div class=" me-auto"> {{ serviceTitle }} </div>
        <div class="svg-container d-flex justify-content-center align-items-center">
          <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.19843 12.8516C4.42269 13.0759 4.78629 13.0759 5.01055 12.8516L8.6651 9.19704C8.88937 8.97278 8.88937 8.60918 8.6651 8.38492C8.44084 8.16066 8.07724 8.16066 7.85298 8.38492L4.60449 11.6334L1.356 8.38492C1.13174 8.16066 0.768143 8.16066 0.543881 8.38492C0.31962 8.60918 0.31962 8.97278 0.543881 9.19704L4.19843 12.8516ZM4.03024 0.960388L4.03024 12.4455L5.17875 12.4455L5.17875 0.960388L4.03024 0.960388Z" fill="#1C1E2D"/>
          </svg>
        </div>
      </div>
      <div class="terms-description-item mt-2"  v-for="(item, index) in items">
        <label class="d-flex cursor-pointer">
          <CheckBox :checked="$store.state.cleaning.selectedServices.hasOwnProperty(item.serviceKey)"
                    :value="item.serviceKey"
                    @onChecked="handleCheckboxCheck"/>
          <div class="ms-2"> {{ $t(item.serviceKey) }} </div>
        </label>
      </div>
    </div>
  </div>


</template>

<script>
import HardfloorSurfaceIcon from "@/components/Common/Icons/HardfloorSurfaceIcon"
import SoftFloorSurfaceIcon from "@/components/Common/Icons/SoftFloorSurfaceIcon"
import SoftFurnitureIcon from "@/components/Common/Icons/SoftFurnitireIcon"
import LeatherFurnitureIcon from "@/components/Common/Icons/LeatherFurntitureIcon"
import AutocleaningIcon from "@/components/Common/Icons/AutocleaningIcon"
import SanitaryRoomIcon from "@/components/Common/Icons/SanitaryRoomIcon"
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import WindowIcon from "@/components/Common/Icons/WindowIcon.vue";
import MattressesCleanngIcon from "@/components/Common/Icons/MattressesCleanngIcon.vue";
import HomeAppliancesIcon from "@/components/Common/Icons/HomeAppliancesIcon.vue";
import OthersCleaningIcon from "@/components/Common/Icons/OthersCleaningIcon.vue";


export default {
  name: "ServiceItemDropdown",
  props: {
    serviceTitle: {
      type: String,
      default: ''
    },
    serviceKey: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    price: {
      type: Number,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
  },
  components: {
    OthersCleaningIcon,
    HomeAppliancesIcon,
    MattressesCleanngIcon,
    WindowIcon,
    HardfloorSurfaceIcon,
    SoftFloorSurfaceIcon,
    SoftFurnitureIcon,
    LeatherFurnitureIcon,
    AutocleaningIcon,
    SanitaryRoomIcon,
    CheckBox

  },
  data() {
    return {
      termsBoxActive: true,
    }
  },
  methods: {
    toggleTermsBox() {
      this.termsBoxActive = !this.termsBoxActive
    },
    handleCheckboxCheck(e) {
      this.$emit('onSelected', e);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.terms-button-wrapper {
  margin: $spacing-2 0;

  .terms-description-item {
    padding: $spacing-1 $spacing-3 ;
  }
  .terms-description-item {
    font-size: 14px;
  }
  .terms-button-mobile {
    cursor: pointer;
    text-align: center;
    align-items: center;
    font-weight: $font-weight-button-regular;
    font-size: $font-size-button-m;
    padding: 10px 18px;
    border-radius: 10px;
    color: $color-text-primary;
    background: #F6F6F6;
    .svg-container {
      background: $color-grey-100;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      svg {
        transform: rotate(180deg);
      }
    }

  }


}


.open-terms-box {
  .terms-button-mobile {
    .svg-container {
      svg {
        transform: rotate(0deg);
      }
    }
  }
  .terms-description-item {
    display: none;
  }
}


</style>